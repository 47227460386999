import React, { useContext } from "react"
import {
    Flex,
    Heading,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react"
import {
    FaEllipsisH,
    FaUserPlus,
    FaRandom,
    FaRegEdit,
    FaPlayCircle,
    FaPauseCircle,
    FaRegWindowClose,
} from "react-icons/fa"

import { updateRoom } from "~queries"
import { UserContext } from "~config/user-context"
import { Room } from "~models"

const YourRoomHeader: React.FC<{
    room: Room
    inviteClick: () => void
    editClick: () => void
    deleteClick: () => void
    randomizeClick: () => void
}> = ({ room, inviteClick, editClick, deleteClick, randomizeClick }) => {
    const { user } = useContext(UserContext)

    return (
        <Flex
            justify="space-between"
            direction={["column", "row", "row"]}
            mb={2}
        >
            <Heading fontSize={["lg", "xl"]}>{room?.id}</Heading>
            <Menu>
                <MenuButton
                    as={Button}
                    rightIcon={<FaEllipsisH />}
                    bg="teal"
                    color="white"
                >
                    Actions
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={inviteClick}>
                        <Flex flex={1} justify="space-between">
                            Invite Users
                            <FaUserPlus />
                        </Flex>
                    </MenuItem>
                    <MenuItem onClick={randomizeClick}>
                        <Flex flex={1} justify="space-between">
                            Randomize Teams
                            <FaRandom />
                        </Flex>
                    </MenuItem>
                    {room.ownerId === user!.id && (
                        <>
                            <MenuItem onClick={editClick}>
                                <Flex flex={1} justify="space-between">
                                    Edit Room
                                    <FaRegEdit />
                                </Flex>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    updateRoom(room.id, {
                                        inGame: !room.inGame,
                                    })
                                }
                            >
                                <Flex flex={1} justify="space-between">
                                    {!room.inGame ? (
                                        <>
                                            Start Game <FaPlayCircle />
                                        </>
                                    ) : (
                                        <>
                                            End Game <FaPauseCircle />
                                        </>
                                    )}
                                </Flex>
                            </MenuItem>
                            <MenuItem
                                bg="rgba(255,100,100,0.2)"
                                onClick={deleteClick}
                            >
                                <Flex flex={1} justify="space-between">
                                    Delete Room <FaRegWindowClose />
                                </Flex>
                            </MenuItem>
                        </>
                    )}
                </MenuList>
            </Menu>
        </Flex>
    )
}

export default YourRoomHeader
