import React, { useState } from "react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useToast,
    Spinner,
} from "@chakra-ui/react"
import { navigate } from "gatsby"

import { deleteRoom } from "~queries"

const DeleteRoomModal: React.FC<{
    isOpen: boolean
    onClose: () => void
    roomId: string
}> = ({ isOpen, onClose, roomId }) => {
    const toast = useToast()

    const [isUpdating, setIsUpdating] = useState(false)

    const confirm = async () => {
        const result = await deleteRoom(roomId)
        if (result.status === "success") {
            navigate("/")
            toast({
                title: "Room deleted.",
                description: `Successfully deleted room ID: ${roomId}.`,
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        } else {
            toast({
                title: "Room delete failed.",
                description: result.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            })
        }
        onClose()
        setIsUpdating(false)
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent p="20px">
                <ModalHeader>Delete Room</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Are you sure you want to delete this room? You cannot undo
                    this.
                </ModalBody>
                <ModalFooter>
                    <Button bg="tomato" mr={3} onClick={confirm}>
                        {isUpdating ? <Spinner /> : "Delete"}
                    </Button>
                    <Button variant="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default DeleteRoomModal
