import React, { useState, useEffect, useContext } from "react"
import {
    Flex,
    Heading,
    Text,
    Divider,
    Button,
    Spinner,
    Tooltip,
    Link,
} from "@chakra-ui/react"
import { FaDiscord, FaPlus } from "react-icons/fa"

import ResponsiveBlock from "~components/shared/responsive-block"
import InviteUsersModal from "~components/shared/invite-users-modal"
import EditRoomModal from "~components/shared/edit-room-modal"
import { UserContext } from "~config/user-context"
import { getUsersByIds, useUserRoomListener, updateRoom } from "~queries"
import { User } from "~models"
import DeleteRoomModal from "~components/shared/delete-room-modal"
import ParticipantsTable from "~components/your-room/participants-table"
import YourRoomHeader from "~components/your-room/your-room-header"

const YourRoom = () => {
    const { user } = useContext(UserContext)
    const [participants, setParticipants] = useState<User[]>([])
    const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false)
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)
    const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false)

    const { room, isLoading } = useUserRoomListener(user!.id)

    useEffect(() => {
        if (!room) return
        getUsersByIds(room.participants).then(res => {
            if (res.data) setParticipants(res.data)
        })
    }, [room])

    // This is literally me just going way too ham on a very easy thing.
    // We just adding you to one team and taking you out of the other.
    function joinTeam(userId: string, color: "blue" | "red") {
        if (!room) return
        const colors = color === "blue" ? ["blue", "red"] : ["red", "blue"]
        const team = (colors[0] + "Team") as "redTeam" | "blueTeam"
        const otherTeam = (colors[1] + "Team") as "redTeam" | "blueTeam"
        updateRoom(room.id, {
            [team]: [...room[team], userId],
            [otherTeam]: [
                ...room[otherTeam].filter((id: string) => id !== userId),
            ],
        })
    }

    function leaveTeams() {
        if (!room || !user) return
        updateRoom(room.id, {
            redTeam: [...room.redTeam.filter((id: string) => id !== user!.id)],
            blueTeam: [
                ...room.blueTeam.filter((id: string) => id !== user!.id),
            ],
        })
    }

    function randomizeParticipants() {
        var tmpParticipants = participants
        for (var i = tmpParticipants.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1))
            var temp = tmpParticipants[i]
            tmpParticipants[i] = tmpParticipants[j]
            tmpParticipants[j] = temp
        }

        const blueTeam: string[] = []
        const redTeam: string[] = []
        tmpParticipants.map(async (participant, index) => {
            if (index < participants.length / 2) {
                redTeam.push(participant.id)
            } else {
                blueTeam.push(participant.id)
            }
        })
        updateRoom(room!.id, {
            redTeam,
            blueTeam,
        })
    }

    if (isLoading) {
        return (
            <Flex w="100%" h="100vh" justify="center" align="center">
                <Spinner size="xl" />
            </Flex>
        )
    }

    if (!room) {
        return (
            <Flex
                w="100%"
                h="100vh"
                justify="center"
                align="center"
                direction="column"
            >
                <Heading>No Room Found!</Heading>
                <Text>Use the Queues page to find some teammates.</Text>
            </Flex>
        )
    }

    return (
        <>
            <ResponsiveBlock>
                <YourRoomHeader
                    room={room}
                    inviteClick={() => setInviteModalIsOpen(true)}
                    editClick={() => setEditModalIsOpen(true)}
                    deleteClick={() => setDeleteAlertIsOpen(true)}
                    randomizeClick={() => randomizeParticipants()}
                />
                <Divider />
                <Flex color="teal" mt={2} mb={8} align="center">
                    <Tooltip label="Voice Chat Link">
                        <span>
                            <FaDiscord />
                        </span>
                    </Tooltip>{" "}
                    <Tooltip label="Make sure you trust this link before visiting!">
                        <Link
                            isExternal
                            href={room.discordLink}
                            pl={2}
                            overflowWrap="anywhere"
                        >
                            {room.discordLink}
                        </Link>
                    </Tooltip>
                </Flex>
                <Flex justify="space-between">
                    <Heading fontSize="md" color="primary">
                        Unassigned
                    </Heading>
                    <Button onClick={() => leaveTeams()}>
                        Join Spectators
                    </Button>
                </Flex>
                <Divider my={4} />
                <ParticipantsTable
                    participants={participants.filter(
                        ({ id }) =>
                            !room?.redTeam.includes(id) &&
                            !room?.blueTeam.includes(id)
                    )}
                    room={room}
                />
                <Flex justify="space-between" mt={8}>
                    <Heading fontSize="md" color="tomato">
                        Red Team
                    </Heading>
                    {!room?.redTeam.includes(user!.id) && (
                        <Button onClick={() => joinTeam(user!.id, "red")}>
                            Join Team <Flex p={2} />
                            <FaPlus />
                        </Button>
                    )}
                </Flex>
                <Divider my={4} />
                <ParticipantsTable
                    participants={participants.filter(({ id }) =>
                        room?.redTeam.includes(id)
                    )}
                    room={room}
                />

                <Flex justify="space-between" mt={8}>
                    <Heading fontSize="md" color="turquoise">
                        Blue Team
                    </Heading>
                    {!room?.blueTeam.includes(user!.id) && (
                        <Button onClick={() => joinTeam(user!.id, "blue")}>
                            Join Team
                            <Flex p={2} /> <FaPlus />
                        </Button>
                    )}
                </Flex>
                <Divider my={4} />
                <ParticipantsTable
                    participants={participants.filter(({ id }) =>
                        room?.blueTeam.includes(id)
                    )}
                    room={room}
                />
            </ResponsiveBlock>
            <InviteUsersModal
                isOpen={inviteModalIsOpen}
                onClose={() => setInviteModalIsOpen(false)}
                room={room}
            />
            <EditRoomModal
                isOpen={editModalIsOpen}
                onClose={() => setEditModalIsOpen(false)}
                editRoom={room}
            />
            <DeleteRoomModal
                isOpen={deleteAlertIsOpen}
                onClose={() => setDeleteAlertIsOpen(false)}
                roomId={room.id}
            />
        </>
    )
}

export default YourRoom
