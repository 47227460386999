import React, { useContext } from "react"
import { Flex, Text, Button } from "@chakra-ui/react"

import { UserContext } from "~config/user-context"
import { User, Room } from "~models"
import { removeUserFromRoom } from "~queries"

const ParticipantsTable: React.FC<{ participants: User[]; room?: Room }> = ({
    participants,
    room,
}) => {
    const { user } = useContext(UserContext)

    return (
        <>
            {participants.map((participant, index) => (
                <Flex
                    key={participant.id}
                    bg={index % 2 === 0 ? "rgba(170,170,170,0.1)" : "none"}
                    px={4}
                    h={"60px"}
                    borderBottom="1px solid rgba(170,170,170,0.4)"
                    justify="space-between"
                    align="center"
                >
                    <Text>{participant.username}</Text>
                    {user!.id === room?.ownerId && participant.id !== user!.id && (
                        <Button
                            bg="tomato"
                            h={30}
                            onClick={() =>
                                removeUserFromRoom(participant.id, room.id)
                            }
                        >
                            Kick
                        </Button>
                    )}
                </Flex>
            ))}
        </>
    )
}

export default ParticipantsTable
